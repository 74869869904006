<template>
    <b-container fluid>
        <b-card no-body class="p-1 mb-2">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>
            <b-collapse id="filters">
                <div class="card-body">
                    <b-row>
                        <b-col md="4">
                            <label for="name">Наименование</label>
                            <b-input id="name" v-model="filters.name" type="text" debounce="500" />
                        </b-col>
                        <b-col md="4">
                            <label for="date-from">Дата от</label>
                            <b-form-datepicker
                                id="date-from"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                v-model="filters.date_from"
                                :max="filters.date_to"

                            />
                        </b-col>
                        <b-col md="4">
                            <label for="date-to">Дата до</label>
                            <b-form-datepicker
                                id="date-to"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                locale="ru"
                                placeholder=""
                                v-model="filters.date_to"
                                :min="filters.date_from"

                            />
                        </b-col>
                        <b-col md="4">
                            <label>Подразделение</label>
                            <department-select v-model="filters.department"/>
                        </b-col>
                        <b-col md="4">
                            <label for="status">Статус</label>
                            <b-form-select id="status" v-model="filters.status" :options="statusOptions" />
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </b-card>
        <b-table
            :items="items"
            :fields="fields"
            @row-dblclicked="toForm"
            hover
            responsive
            head-variant="dark"
        >
            <template #cell(actions)="row">
                <b-button
                    v-show="row.item.status && row.item.status === STATUS_COMPLETED"
                    @click="toForm(row.item)"
                    size="sm"
                    variant="info"
                >
                    <b-icon-eye-fill/>
                </b-button>
                <b-button
                    v-show="!row.item.deleted_at && row.item.status !== STATUS_COMPLETED"
                    @click="toForm(row.item)"
                    size="sm"
                    variant="warning"
                >
                    <b-icon-pencil-square/>
                </b-button>
                <b-button
                    v-show="!row.item.deleted_at && !row.item.status"
                    @click="destroy(row.item)"
                    size="sm"
                    variant="danger"
                >
                    <b-icon-trash-fill/>
                </b-button>
                <b-button
                    v-show="row.item.deleted_at"
                    @click="restore(row.item)"
                    size="sm"
                    variant="light"
                >
                    <b-icon-arrow-return-left/>
                </b-button>
            </template>
        </b-table>
        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {PLANNED_SANITARY_INSPECTIONS_ENDPOINT} from '@utils/endpoints';
import {DepartmentSelect} from '@components';
import lsfMixin from '@utils/localStorageFilterMixin';

import moment from 'moment';

const STATUS_COMPLETED = 'completed';
const STATUS_STARTED = 'started';
const PRIMARY_FINISHED = 'primary_finished';
const SECONDARY_STARTED = 'secondary_started';

export default {
    name: 'Index',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: 'Наименование',
                },
                {
                    key: 'department',
                    label: 'Подразделение',
                    formatter(value) {
                        return value ? value.name : null;
                    }
                },
                {
                    key: 'started_at',
                    label: 'Дата начала',
                    formatter(value) {
                        return value ? moment(value).format('DD.MM.YYYY') : null;
                    }
                },
                {
                    key: 'status',
                    label: 'Статус',
                    formatter(value, key, item) {
                        if (value !== STATUS_COMPLETED) {
                            if (item.correcting_inconsistencies_finished_at
                                || item.is_overdued
                            ) {
                                return 'Вторичная проверка';
                            }

                            if (item.primary_finished_at) {
                                return 'Первичная проверка завершена';
                            }
                        }

                        if (value === STATUS_COMPLETED) {
                            return 'Проверка окончена';
                        } else if (value === STATUS_STARTED) {
                            return 'Проверка начата';
                        }
                    }
                },
                {
                    key: 'actions',
                    label: 'Действия',
                },
            ],
            items: [],
            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 25,
            },
            moment,
            STATUS_COMPLETED,
            filters: {
                date_from: moment().format('YYYY-MM-DD'),
                date_to: moment().format('YYYY-MM-DD'),
                department_id: null,
                name: null,
                status: null,
            },
            statusOptions: [
                {
                    value: null,
                    text: 'Выберите статус',
                },
                {
                    value: STATUS_STARTED,
                    text: 'начатые',
                },
                {
                    value: PRIMARY_FINISHED,
                    text: 'завершенные первичные проверки',
                },
                {
                    value: SECONDARY_STARTED,
                    text: 'вторичная проверка',
                },
                {
                    value: STATUS_COMPLETED,
                    text: 'законченные',
                },
            ]
        }
    },
    mixins: [lsfMixin],
    components: {
        DepartmentSelect,
    },
    computed: {
        userIsSanitaryController() {
            return this.$auth.user().is_admin
                || (
                    this.$auth.user().current_work_position
                    && this.$auth.user().current_work_position.key === 'sanitary_control_specialist'
                );
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            },
        },
    },
    methods: {
        prepareFilters() {
            let filters = {};
            for (let filterName in this.getFilters) {
                if (filterName === 'department') {
                    filters.department_id = this.getFilters[filterName].id;
                } else {
                    filters[filterName] = this.getFilters[filterName];
                }
            }

            return filters;
        },
        applyFilters() {
            let params = {
                filters: this.prepareFilters(),
                page: this.meta.current_page,
            };

            this.$http.get(PLANNED_SANITARY_INSPECTIONS_ENDPOINT, {params})
                .then((response) => {
                    this.items = response.data.data.data;

                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                });
        },
        toForm(item) {
            if (!item.deleted_at) {
                this.$router.push({
                    name: 'PlannedSanitaryInspectionShow',
                    params: {
                        id: item.id
                    }
                });
            }
        },
        destroy(item) {
            this.$http.delete(`${PLANNED_SANITARY_INSPECTIONS_ENDPOINT}/${item.id}`).then(this.applyFilters);
        },
        restore(item) {
            this.$http.patch(`${PLANNED_SANITARY_INSPECTIONS_ENDPOINT}/${item.id}/restore`).then(this.applyFilters);
        },
        allowedToEdit(item) {
            const current = moment().format('YYYY-MM-DD');
            return moment(current).isSame(item.started_at);
        }
    },
    mounted() {
        this.initFilters(this, this.filters);
    },
}
</script>
